let baseUrlToUse = 'http://127.0.0.1:8080/v1/';

if (process.env.NODE_ENV === 'production') {
  baseUrlToUse = 'https://api.humient.com/v1/';
}

export const BASE_URL = baseUrlToUse;

export const LOGIN_URL = `${BASE_URL}auth/login`;
export const LOGOUT_URL = `${BASE_URL}auth/logout`;
export const ME_URL = `${BASE_URL}me`;
export const CHANGE_PASSWORD_URL = `${BASE_URL}auth/change-password`;
export const PRESCRIPTION_ROUTES_URL = `${BASE_URL}prescription/routes`;
export const PRESCRIPTION_SITES_URL = `${BASE_URL}prescription/sites`;
export const ADMIN_SWITCH_USER_URL = `${BASE_URL}auth/switch-user`;
export const ADMIN_GET_USERS_URL = `${BASE_URL}auth/get-all-users`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}auth/forgot-password`;
export const DEVICE_TYPES_URL = `${BASE_URL}device-types`;
export const DEVICES_URL = `${BASE_URL}devices`;
export const MEDICATIONS_URL = `${BASE_URL}medications`;
export const ES_MEDICATIONS_URL = `${BASE_URL}es-medications`;
export const PHONES_URL = `${BASE_URL}phones`;
export const BOARDS_URL = `${BASE_URL}boards`;
export const CHANGE_PIN_CODE_URL = `${BASE_URL}auth/change-pin-code`;
export const OCCUPANCY_URL = `${BASE_URL}occupancy`;

export function orgBranchesURL(id) {
  return `${BASE_URL}organization/${id}/branches`;
}

export function orgResidentsURL(id) {
  return `${BASE_URL}organization/${id}/residents`;
}

export function orgAllResidentsURL(id) {
  return `${BASE_URL}organization/${id}/all-residents`;
}

export function branchResidentsURL(id) {
  return `${BASE_URL}branch/${id}/residents`;
}

export function residentURL(id) {
  return `${BASE_URL}resident/${id}`;
}

export function residentPrescriptionURL(id) {
  return `${BASE_URL}resident/${id}/prescriptions`;
}

export function prescriptionURL(id) {
  return `${BASE_URL}prescription/${id}`;
}

export function downloadURL(key) {
  return `${BASE_URL}auth/download?filename=${key}`;
}

export function branchURL(id) {
  return `${BASE_URL}branch/${id}`;
}

export function bedURL(id) {
  return `${BASE_URL}bed/${id}`;
}

export function branchBedsURL(id) {
  return `${BASE_URL}branch/${id}/beds`;
}

export function eventURL(id) {
  return `${BASE_URL}event/${id}`;
}

export function branchEventsURL(id) {
  return `${BASE_URL}branch/${id}/events`;
}

export function orgUsersURL(id) {
  return `${BASE_URL}organization/${id}/users`;
}

export function eventSubscriptionURL(id) {
  return `${BASE_URL}event-subscription/${id}`;
}

export function eventSubscriptionsURL(id) {
  return `${BASE_URL}event/${id}/subscriptions`;
}

export function userURL(id) {
  return `${BASE_URL}user/${id}`;
}

export function residentPhotoURL(id) {
  return `${BASE_URL}resident/${id}/photo`;
}

export function organizationFormsURL(id) {
  return `${BASE_URL}organization/${id}/forms`;
}

export function formURL(id) {
  return `${BASE_URL}form/${id}`;
}

export function formFormFieldsURL(id) {
  return `${BASE_URL}form/${id}/fields`;
}

export function formFieldURL(id) {
  return `${BASE_URL}form-field/${id}`;
}

export function formFieldsSortURL(id) {
  return `${BASE_URL}form/${id}/fields/sort`;
}

export function completedFormURL(id) {
  return `${BASE_URL}completed-form/${id}`;
}

export function completedFormFieldURL(id) {
  return `${BASE_URL}completed-form-field/${id}`;
}

export function completedFormsURL(id) {
  return `${BASE_URL}form/${id}/completed`;
}

export function completedFormsSearchURL(id) {
  return `${BASE_URL}form/${id}/search`;
}

export function residentFormsURL(id, page, size) {
  return `${BASE_URL}resident/${id}/forms?page=${page}&per_page=${size}`;
}

export function heartRateURL(id) {
  return `${BASE_URL}heart-rate/${id}`;
}

export function bloodPressureURL(id) {
  return `${BASE_URL}blood-pressure/${id}`;
}

export function temperatureURL(id) {
  return `${BASE_URL}temperature/${id}`;
}

export function weightURL(id) {
  return `${BASE_URL}weight/${id}`;
}

export function glucoseURL(id) {
  return `${BASE_URL}glucose/${id}`;
}

export function spO2URL(id) {
  return `${BASE_URL}spo2/${id}`;
}

export function respirationRateURL(id) {
  return `${BASE_URL}respiration-rate/${id}`;
}

export function ecgURL(id) {
  return `${BASE_URL}ecg/${id}`;
}

export function residentBloodPressuresURL(id) {
  return `${BASE_URL}resident/${id}/blood-pressures`;
}

export function residentTemperaturesURL(id) {
  return `${BASE_URL}resident/${id}/temperatures`;
}

export function residentHeartRatesURL(id) {
  return `${BASE_URL}resident/${id}/heart-rates`;
}

export function residentWeightsURL(id) {
  return `${BASE_URL}resident/${id}/weights`;
}

export function residentSpO2sURL(id) {
  return `${BASE_URL}resident/${id}/spo2s`;
}

export function residentGlucosesURL(id) {
  return `${BASE_URL}resident/${id}/glucoses`;
}

export function residentRespirationRatesURL(id) {
  return `${BASE_URL}resident/${id}/respiration-rates`;
}

export function residentECGsURL(id) {
  return `${BASE_URL}resident/${id}/ecgs`;
}

export function temperatureThresholdURL(id) {
  return `${BASE_URL}temperature-threshold/${id}`;
}

export function residentTemperatureThresholdURL(id) {
  return `${BASE_URL}resident/${id}/temperature-threshold`;
}

export function weightThresholdURL(id) {
  return `${BASE_URL}weight-threshold/${id}`;
}

export function residentWeightThresholdURL(id) {
  return `${BASE_URL}resident/${id}/weight-threshold`;
}

export function bloodPressureThresholdURL(id) {
  return `${BASE_URL}blood-pressure-threshold/${id}`;
}

export function residentBloodPressureThresholdURL(id) {
  return `${BASE_URL}resident/${id}/blood-pressure-threshold`;
}

export function heartRateThresholdURL(id) {
  return `${BASE_URL}heart-rate-threshold/${id}`;
}

export function residentHeartRateThresholdURL(id) {
  return `${BASE_URL}resident/${id}/heart-rate-threshold`;
}

export function glucoseThresholdURL(id) {
  return `${BASE_URL}glucose-threshold/${id}`;
}

export function residentGlucoseThresholdURL(id) {
  return `${BASE_URL}resident/${id}/glucose-threshold`;
}

export function spO2ThresholdURL(id) {
  return `${BASE_URL}spo2-threshold/${id}`;
}

export function residentSpO2ThresholdURL(id) {
  return `${BASE_URL}resident/${id}/spo2-threshold`;
}

export function respirationRateThresholdURL(id) {
  return `${BASE_URL}respiration-rate-threshold/${id}`;
}

export function residentRespirationRateThresholdURL(id) {
  return `${BASE_URL}resident/${id}/respiration-rate-threshold`;
}

export function printResidentVitalsURL(id, fromDate, toDate) {
  return `${BASE_URL}print/resident/${id}/vitals?from=${fromDate}&to=${toDate}`;
}

export function completedFormCommentsURL(id) {
  return `${BASE_URL}completed-form/${id}/comments`;
}

export function completedFormCommentURL(id) {
  return `${BASE_URL}completed-form-comment/${id}`;
}

export function printCompletedFormURL(id) {
  return `${BASE_URL}print/completed-form/${id}`;
}

export function organizationResidentFormsURL(id) {
  return `${BASE_URL}organization/${id}/resident-forms`;
}

export function residentArchiveURL(id) {
  return `${BASE_URL}resident/${id}/archive`;
}

export function branchArchivedResidentsURL(id) {
  return `${BASE_URL}branch/${id}/archived-residents`;
}

export function prescriptionRestartURL(id) {
  return `${BASE_URL}prescription/${id}/restart`;
}

export function vitalsThresholdInstructionsURL(id) {
  return `${BASE_URL}vitals-threshold-instructions/${id}`;
}

export function organizationVitalsThresholdInstructionsURL(id) {
  return `${BASE_URL}organization/${id}/vitals-threshold-instructions`;
}

export function residentVitalsURL(id) {
  return `${BASE_URL}resident/${id}/vitals`;
}

export function branchVitalsRequirementsCheckURL(id) {
  return `${BASE_URL}report/branch/${id}/vitals-requirements-check`;
}

export function branchVitalsRequirementsURL(id) {
  return `${BASE_URL}branch-vitals-requirements/${id}`;
}

export function branchBranchVitalsRequirementsURL(id) {
  return `${BASE_URL}branch/${id}/vitals-requirements`;
}

export function branchVitalsRequirementsCheckCountURL(id) {
  return `${BASE_URL}report/branch/${id}/vitals-requirements-check-count`;
}

export function userVitalsReportSubscriptionsURL(userId, branchId) {
  return `${BASE_URL}user/${userId}/vitals-report-subscriptions/branch/${branchId}`;
}

export function vitalsReportSubscriptionURL(id) {
  return `${BASE_URL}vitals-report-subscription/${id}`;
}

export function passwordRecoveryUrl(uuid) {
  return `${BASE_URL}auth/password-recovery/${uuid}`;
}

export function disableUserUrl(id) {
  return `${BASE_URL}user/${id}/disable`;
}

export function restoreUserUrl(id) {
  return `${BASE_URL}user/${id}/restore`;
}

export function deleteUserUrl(id) {
  return `${BASE_URL}user/${id}/delete`;
}

export function orgNewUsersURL(id) {
  return `${BASE_URL}organization/${id}/new-users`;
}

export function orgRolesURL(id) {
  return `${BASE_URL}organization/${id}/roles`;
}

export function inactiveUserEmailURL(token) {
  return `${BASE_URL}inactive-user-email/${token}`;
}

export function newUserURL(id) {
  return `${BASE_URL}new-user/${id}`;
}

export function addUserURL(token) {
  return `${BASE_URL}add-user/${token}`;
}

export function residentRemindersURL(id) {
  return `${BASE_URL}resident/${id}/events`;
}

export function roleURL(id) {
  return `${BASE_URL}role/${id}`;
}

export function branchesAndFormsForPolicyByOrgURL(id) {
  return `${BASE_URL}organization/${id}/policy-fields`;
}

export function kinResidentsURL(id) {
  return `${BASE_URL}kin/${id}/residents`;
}

export function kinResidentURL(id) {
  return `${BASE_URL}kin-resident/${id}`;
}

export function kinResidentVitalsURL(id) {
  return `${BASE_URL}kin-resident/${id}/vitals`;
}

export function linkedKinURL(id) {
  return `${BASE_URL}resident/${id}/kin`;
}

export function organizationKinURL(id) {
  return `${BASE_URL}organization/${id}/kin`;
}

export function residentKinURL(id) {
  return `${BASE_URL}resident-kin/${id}`;
}

export function usersByRoleURL(id) {
  return `${BASE_URL}role/${id}/users`;
}

export function prescriptionAdministrationURL(id) {
  return `${BASE_URL}resident/${id}/prescription_administration`;
}

export function productServiceURL(id) {
  return `${BASE_URL}product-service/${id}`;
}

export function productServiceUsageURL(id) {
  return `${BASE_URL}product-service-usage/${id}`;
}

export function productServiceUsageSummaryURL(id) {
  return `${BASE_URL}report/branch/${id}/product-service-usage-summary`;
}

export function residentProductServiceUsagesURL(id) {
  return `${BASE_URL}resident/${id}/product-service-usages`;
}

export function organizationProductsAndServicesURL(id) {
  return `${BASE_URL}organization/${id}/products-and-services`;
}

export function deviceTypeURL(id) {
  return `${BASE_URL}device-type/${id}`;
}

export function deviceTypeDevicesURL(id) {
  return `${BASE_URL}device-type/${id}/devices`;
}

export function deviceURL(id) {
  return `${BASE_URL}device/${id}`;
}

export function formFormSectionsURL(id) {
  return `${BASE_URL}form/${id}/form-sections`;
}

export function formSectionURL(id) {
  return `${BASE_URL}form-section/${id}`;
}

export function organizationWorkShiftsURL(id) {
  return `${BASE_URL}organization/${id}/work-shifts`;
}

export function workShiftURL(id) {
  return `${BASE_URL}work-shift/${id}`;
}

export function branchEmployeesURL(id) {
  return `${BASE_URL}branch/${id}/employees`;
}

export function scheduledWorkShiftURL(id) {
  return `${BASE_URL}scheduled-work-shift/${id}`;
}

export function branchScheduledWorkShiftsURL(id) {
  return `${BASE_URL}branch/${id}/scheduled-work-shifts`;
}

export function scheduledWorkShiftAttendanceURL(id) {
  return `${BASE_URL}scheduled-work-shift/${id}/attendance`;
}

export function branchOccupancyURL(id) {
  return `${BASE_URL}branch/${id}/occupancy`;
}

export function branchFormRequirementsCheckURL(id) {
  return `${BASE_URL}report/branch/${id}/form-requirements-check`;
}

export function vitalsSearchByBranchURL(branchId) {
  return `${BASE_URL}report/branch/${branchId}/vitals-es`;
}

export function vitalsSearchByResidentURL(residentId) {
  return `${BASE_URL}report/resident/${residentId}/vitals`;
}

export function residentInvoicesURL(id) {
  return `${BASE_URL}resident/${id}/invoices`;
}

export function invoiceURL(id) {
  return `${BASE_URL}invoice/${id}`;
}

export function invoiceInvoiceEmailLogsURL(id) {
  return `${BASE_URL}invoice/${id}/email-logs`;
}

export function newUserResendEmailURL(id) {
  return `${BASE_URL}new-user/${id}/resend-email`;
}

export function residentInvoiceGenerationHelperURL(id) {
  return `${BASE_URL}resident/${id}/invoice-generation-helper`;
}

export function kinInvoicesURL(id) {
  return `${BASE_URL}kin/${id}/invoices`;
}

export function branchProductServiceUsagesURL(id) {
  return `${BASE_URL}branch/${id}/product-service-usages`;
}

/**
 *
 * @param {String|Number} id
 * @param {{branchId: {String}, shouldFallback: {Boolean}}|undefined} options
 * @returns {string}
 */
export function organizationInvoiceDetailsURL(id, options = undefined) {
  let url = `${BASE_URL}organization/${id}/invoice-details`;
  if (options) {
    const { branchId, shouldFallback } = options;
    url = `${url}?branch_id=${branchId}&should_fallback=${shouldFallback ? 'true' : 'false'}`;
  }
  return url;
}

export function invoiceDetailsURL(id) {
  return `${BASE_URL}invoice-details/${id}`;
}

export function residentPricingURL(id) {
  return `${BASE_URL}resident/${id}/pricing`;
}

export function pricingURL(id) {
  return `${BASE_URL}pricing/${id}`;
}

export function branchVitalsRequirementsCheckHistoryURL(branchId) {
  return `${BASE_URL}report/branch/${branchId}/vitals-requirements-check-history`;
}

export function employeeURL(id) {
  return `${BASE_URL}employee/${id}`;
}

export function residentWoundsURL(id) {
  return `${BASE_URL}resident/${id}/wounds`;
}

export function woundURL(id) {
  return `${BASE_URL}wound/${id}`;
}

export function woundWoundProgressesURL(id) {
  return `${BASE_URL}wound/${id}/wound-progresses`;
}

export function woundProgressURL(id) {
  return `${BASE_URL}wound-progress/${id}`;
}

export function branchResidentAddArchiveActionsURL(id) {
  return `${BASE_URL}branch/${id}/resident-add-archive-actions`;
}

export function branchPrescriptionsURL(id) {
  return `${BASE_URL}branch/${id}/prescriptions`;
}

export function branchResidentDetailsURL(id) {
  return `${BASE_URL}branch/${id}/resident-details`;
}

export function residentResidentDetailsURL(id) {
  return `${BASE_URL}resident/${id}/resident-details`;
}

export function medicationURL(id) {
  return `${BASE_URL}medication/${id}`;
}

export function residentMessagesURL(id) {
  return `${BASE_URL}resident/${id}/messages`;
}

export function residentTasksURL(id) {
  return `${BASE_URL}resident/${id}/tasks`;
}

export function taskURL(id) {
  return `${BASE_URL}task/${id}`;
}

export function branchTasksURL(id) {
  return `${BASE_URL}branch/${id}/daily-tasks`;
}

export function vitalsThresholdDefaultURL(id) {
  return `${BASE_URL}vitals-threshold-default/${id}`;
}

export function organizationVitalsThresholdDefaultsURL(id) {
  return `${BASE_URL}organization/${id}/vitals-threshold-defaults`;
}

export function organizationLogoURL(orgId) {
  return `${BASE_URL}organization/${orgId}/logo`;
}

export function completedFormCompletedFormApprovalsURL(id) {
  return `${BASE_URL}completed-form/${id}/completed-form-approvals`;
}

export function completedFormApprovalURL(id) {
  return `${BASE_URL}completed-form-approval/${id}`;
}

export function userBranchPushSubscriptionsURL(userId, branchId) {
  return `${BASE_URL}user/${userId}/branch-push-subscriptions/branch/${branchId}`;
}

export function branchPushSubscriptionURL(id) {
  return `${BASE_URL}branch-push-subscription/${id}`;
}

export function userPDFGenerationsURL(id) {
  return `${BASE_URL}print/user/${id}/pdf-generations`;
}

export function pdfGenerationURL(id) {
  return `${BASE_URL}print/pdf-generation/${id}`;
}

export function phoneURL(id) {
  return `${BASE_URL}phone/${id}`;
}

export function orgActivityURL(id) {
  return `${BASE_URL}organization/${id}/activity`;
}

export function residentContactsURL(id) {
  return `${BASE_URL}resident/${id}/contacts`;
}

export function contactURL(id) {
  return `${BASE_URL}contact/${id}`;
}

export function residentResidentPaymentsURL(id) {
  return `${BASE_URL}resident/${id}/payments`;
}

export function residentPaymentURL(id) {
  return `${BASE_URL}resident-payment/${id}`;
}

export function residentTotalOwedURL(id) {
  return `${BASE_URL}resident/${id}/total-owed`;
}

export function kinResidentPaymentsURL(id) {
  return `${BASE_URL}kin/${id}/payments`;
}

export function residentPaymentResidentPaymentEmailLogsURL(id) {
  return `${BASE_URL}resident-payment/${id}/email-logs`;
}

export function branchOutstandingBalancesURL(id) {
  return `${BASE_URL}branch/${id}/outstanding-balances`;
}

export function residentVitalRequirementsURL(id, vital = null) {
  const arg = vital ? `?vital=${vital}` : '';
  return `${BASE_URL}resident/${id}/vital-requirements${arg}`;
}

export function residentVitalRequirementURL(id) {
  return `${BASE_URL}resident-vital-requirement/${id}`;
}

export function userCSVGenerationsURL(id) {
  return `${BASE_URL}print/user/${id}/csv-generations`;
}

export function csvGenerationURL(id) {
  return `${BASE_URL}print/csv-generation/${id}`;
}

export function organizationFinancesURL(id) {
  return `${BASE_URL}organization/${id}/finances`;
}

export function branchFormReportURL(id) {
  return `${BASE_URL}report/branch/${id}/form-report`;
}

export function specificResidentFormsURL(residentId, formId, page, size) {
  return `${BASE_URL}resident/${residentId}/form/${formId}/completed?page=${page}&per_page=${size}`;
}

export function organizationFormReportFormsURL(id) {
  return `${BASE_URL}organization/${id}/form-report-forms`;
}
