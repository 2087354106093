/**
 *
 * Loading
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Spin from 'antd/lib/spin';
import 'antd/lib/spin/style';

import LogoImage from 'containers/App/images/logo.png';

import colors from 'styles/colors';

const LoadingWrapper = styled.div`
  background-color: ${colors.blue1};
  height: 100%;
  width: 100%;
  position: absolute;
`;

const Centered = styled.div`
  & .ant-spin {
    margin-left: 41px;
  }
  width: 120px;
  height: 74px;
  position: absolute;
  top: 50%;
  left: 50%;

  margin: -30px 0 0 -60px;
`;

const Logo = styled.img`
  margin-top: 10px;
`;

function LoadingPage(props) {
  // Loadable passes pastDelay but authwrapper doesn't.
  const { pastDelay } = props;
  if (pastDelay === undefined || pastDelay) {
    return (
      <LoadingWrapper>
        <Centered>
          <Spin size="large" />
          <Logo src={LogoImage} alt="humient Logo" />
        </Centered>
      </LoadingWrapper>
    );
  }
  return null;
}

LoadingPage.propTypes = {
  pastDelay: PropTypes.bool,
};

export default LoadingPage;
