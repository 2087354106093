import { createGlobalStyle } from 'styled-components';

/* eslint no-unused-expressions: 0 */
const GlobalStyle = createGlobalStyle`
  html,
  body {
    width: 100%;
    line-height: 1.15;
    margin: 0;
    font-size: 14px;
    font-family: Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: Roboto, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  article,
  aside,
  footer,
  header,
  nav,
  section {
    display: block;
  }

  a {
    background-color: transparent; /* 1 */
    text-decoration-skip: objects; /* was -> '-webkit-text-decoration-skip: objects;' */
    text-decoration: none;
    color: inherit;
  }

  a:active,
  a:hover {
    outline-width: 0;
  }

  img {
    border-style: none;
  }

  [hidden] {
    display: none;
  }

  tbody {
    background-color: white;
  }

  #app {
    height: 100%;
  }

  #app > [data-reactroot] {
    height: 100%;
  }

  form > .ant-row {
    display: block;
  }

  .ant-form-item-with-help {
    margin-bottom: 23px !important;
  }

  .ant-form-item-control textarea {
    line-height: 1.15;
  }

  span.anticon {
    color: inherit;
    vertical-align: 1px;
  }
`;

// #app, #app > [data-reactroot] needed for the loading page

export default GlobalStyle;
