import { call, put, takeLatest } from 'redux-saga/effects';


import request from 'utils/request';

import RequestProcessor from 'utils/requestProcessor';

import {
  branchResidentsURL,
  orgBranchesURL,
  ME_URL,
  LOGOUT_URL,
  branchBedsURL,
  residentURL,
  orgUsersURL,
  orgResidentsURL,
  PRESCRIPTION_ROUTES_URL,
  PRESCRIPTION_SITES_URL,
  orgNewUsersURL,
  orgRolesURL,
  organizationProductsAndServicesURL,
  kinInvoicesURL,
  residentResidentDetailsURL,
  branchResidentDetailsURL,
  userPDFGenerationsURL,
  pdfGenerationURL,
  residentArchiveURL,
  kinResidentPaymentsURL,
  linkedKinURL,
  residentContactsURL,
  invoiceURL,
  residentInvoicesURL,
  userCSVGenerationsURL,
  csvGenerationURL,
} from 'utils/urlGenerators';

import {
  LOAD_BRANCHES_ATTEMPT,
  LOAD_BRANCH_RESIDENTS_ATTEMPT,
  LOAD_RESIDENT_ATTEMPT,
  LOAD_CURRENT_USER_ATTEMPT,
  LOGOUT_ATTEMPT,
  LOAD_BRANCH_BEDS_ATTEMPT,
  LOAD_ORG_USERS_ATTEMPT,
  LOAD_RESIDENTS_ATTEMPT,
  LOAD_PRESCRIPTION_ROUTES_ATTEMPT,
  LOAD_PRESCRIPTION_SITES_ATTEMPT,
  LOAD_ORG_INACTIVE_USERS_ATTEMPT,
  LOAD_ORG_ROLES_ATTEMPT,
  LOAD_PRODUCTS_AND_SERVICES_ATTEMPT,
  LOAD_KIN_INVOICES_ATTEMPT,
  LOAD_RESIDENT_RESIDENT_DETAILS_ATTEMPT,
  LOAD_BRANCH_RESIDENT_DETAILS_ATTEMPT,
  SAVE_PDF_GENERATION_ATTEMPT,
  SAVE_RESIDENT_ATTEMPT,
  ARCHIVE_RESIDENT_ATTEMPT,
  DELETE_RESIDENT_ATTEMPT,
  LOAD_KIN_RESIDENT_PAYMENTS_ATTEMPT,
  LOAD_LINKED_KIN_ATTEMPT,
  LOAD_RESIDENT_CONTACTS_ATTEMPT,
  SAVE_RESIDENT_INVOICE_ATTEMPT,
  SAVE_CSV_GENERATION_ATTEMPT,
} from './constants';

import {
  loadBranchesSuccess,
  loadBranchesError,
  loadBranchResidentsSuccess,
  loadBranchResidentsError,
  loadResidentSuccess,
  loadResidentError,
  loadCurrentUserSuccess,
  loadCurrentUserError,
  loadBranchBedsError,
  loadBranchBedsSuccess,
  loadOrgUsersError,
  loadOrgUsersSuccess,
  loadResidentsSuccess,
  loadResidentsError,
  loadPrescriptionRoutesSuccess,
  loadPrescriptionRoutesError,
  loadPresriptionSitesSuccess,
  loadPresriptionSitesError,
  loadOrgInactiveUsersSuccess,
  loadOrgInactiveUsersError,
  loadOrgRolesSuccess,
  loadOrgRolesError,
  loadProductsAndServicesSuccess,
  loadProductsAndServicesError,
  loadKinInvoicesSuccess,
  loadKinInvoicesError,
  loadResidentResidentDetailsSuccess,
  loadResidentResidentDetailsError,
  loadBranchResidentDetailsSuccess,
  loadBranchResidentDetailsError,
  loadKinResidentPaymentsSuccess,
  loadKinResidentPaymentsError,
  loadLinkedKinError,
  loadLinkedKinSuccess,
  loadResidentContactsError,
  loadResidentContactsSuccess,
} from './actions';

export function* attemptLoadBranches({ orgId }) {
  const options = {
    url: orgBranchesURL(orgId),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const response = yield call(request, options.url, options);
    yield put(loadBranchesSuccess(response));
  } catch (error) {
    yield put(loadBranchesError(error.response));
  }
}

export function* attemptLoadBranchResidents(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'GET',
    url: branchResidentsURL(action.branchId),
    successAction: data => loadBranchResidentsSuccess(action.branchId, data),
    failureAction: error => loadBranchResidentsError(action.branchId, error),
    ...action,
  });
}

export function* attemptLoadResident(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'GET',
    url: residentURL(action.id),
    successAction: data => loadResidentSuccess(action.id, data),
    failureAction: error => loadResidentError(action.id, error),
    ...action,
  });
}

export function* attemptLoadMe() {
  try {
    const response = yield call(request, ME_URL);
    yield put(loadCurrentUserSuccess(response));
  } catch (error) {
    yield put(loadCurrentUserError(error.response));
  }
}

export function* attemptLogout(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'POST',
    url: LOGOUT_URL,
    ...action,
  });
}

export function* attemptLoadBranchBeds(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'GET',
    url: branchBedsURL(action.branchId),
    successAction: data => loadBranchBedsSuccess(action.branchId, data),
    failureAction: error => loadBranchBedsError(action.branchId, error),
    ...action,
  });
}

export function* attemptLoadOrgUsers(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'GET',
    url: orgUsersURL(action.orgId),
    successAction: loadOrgUsersSuccess,
    failureAction: loadOrgUsersError,
    ...action,
  });
}

export function* attemptLoadOrgResidents(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'GET',
    url: orgResidentsURL(action.orgId),
    successAction: loadResidentsSuccess,
    failureAction: loadResidentsError,
    ...action,
  });
}

export function* attemptLoadPrescriptionRoutes(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'GET',
    url: PRESCRIPTION_ROUTES_URL,
    successAction: loadPrescriptionRoutesSuccess,
    failureAction: loadPrescriptionRoutesError,
    ...action,
  });
}

export function* attemptLoadPrescriptionSites(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'GET',
    url: PRESCRIPTION_SITES_URL,
    successAction: loadPresriptionSitesSuccess,
    failureAction: loadPresriptionSitesError,
    ...action,
  });
}

export function* attemptLoadOrgInactiveUsers(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'GET',
    url: orgNewUsersURL(action.orgId),
    successAction: loadOrgInactiveUsersSuccess,
    failureAction: loadOrgInactiveUsersError,
    ...action,
  });
}

export function* attemptLoadOrgRoles(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'GET',
    url: orgRolesURL(action.orgId),
    successAction: loadOrgRolesSuccess,
    failureAction: loadOrgRolesError,
    ...action,
  });
}

export function* attemptProductsAndServicesLoad(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'GET',
    url: organizationProductsAndServicesURL(action.orgId),
    successAction: loadProductsAndServicesSuccess,
    failureAction: loadProductsAndServicesError,
    ...action,
  });
}

export function* attemptKinInvoicesLoad(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'GET',
    url: kinInvoicesURL(action.kinId),
    successAction: loadKinInvoicesSuccess,
    failureAction: loadKinInvoicesError,
    ...action,
  });
}

export function* attemptResidentResidentDetailsLoad(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'GET',
    url: residentResidentDetailsURL(action.residentId),
    successAction: data => loadResidentResidentDetailsSuccess(action.residentId, data),
    failureAction: error => loadResidentResidentDetailsError(action.residentId, error),
    ...action,
  });
}

export function* attemptLoadBranchResidentDetails(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'GET',
    url: branchResidentDetailsURL(action.branchId),
    successAction: data => loadBranchResidentDetailsSuccess(action.branchId, data),
    failureAction: error => loadBranchResidentDetailsError(action.branchId, error),
    ...action,
  });
}

export function* attemptPDFGeneration(action) {
  const processor = new RequestProcessor();
  const params = Object.assign({}, action);
  if (params.id) {
    params.method = 'PUT';
    params.url = pdfGenerationURL(params.id);
  } else {
    params.method = 'POST';
    params.url = userPDFGenerationsURL(params.userId);
  }
  yield call(processor.process, params);
}

export function* attemptResidentSave(action) {
  const processor = new RequestProcessor();
  const params = Object.assign({}, action);
  if (params.data.id) {
    params.method = 'PUT';
    params.url = residentURL(params.data.id);
  } else {
    params.method = 'POST';
    params.url = branchResidentsURL(params.data.branch_id);
  }
  yield call(processor.process, params);
}

export function* attemptResidentArchive(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'POST',
    url: residentArchiveURL(action.residentId),
    ...action,
  });
}

export function* attemptResidentDelete(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'DELETE',
    url: residentURL(action.data.id),
    ...action,
  });
}

export function* attemptKinResidentPaymentsLoad(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'GET',
    url: kinResidentPaymentsURL(action.kinId),
    successAction: loadKinResidentPaymentsSuccess,
    failureAction: loadKinResidentPaymentsError,
    ...action,
  });
}

export function* attemptLinkedKinLoad(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'GET',
    url: linkedKinURL(action.residentId),
    successAction: data => loadLinkedKinSuccess(data, action.residentId),
    failureAction: error => loadLinkedKinError(error, action.residentId),
    ...action,
  });
}

export function* attemptResidentContactsLoad(action) {
  const processor = new RequestProcessor();
  yield call(processor.process, {
    method: 'GET',
    url: residentContactsURL(action.residentId),
    successAction: data => loadResidentContactsSuccess(data, action.residentId),
    failureAction: error => loadResidentContactsError(error, action.residentId),
    ...action,
  });
}

export function* attemptResidentInvoiceSave(action) {
  const params = Object.assign({}, action);
  if (params.data.id) {
    params.method = 'PUT';
    params.url = invoiceURL(action.data.id);
  } else {
    params.method = 'POST';
    params.url = residentInvoicesURL(action.residentId);
  }
  const processor = new RequestProcessor();
  yield call(processor.process, params);
}

export function* attemptCSVGeneration(action) {
  const processor = new RequestProcessor();
  const params = Object.assign({}, action);
  if (params.id) {
    params.method = 'PUT';
    params.url = csvGenerationURL(params.id);
  } else {
    params.method = 'POST';
    params.url = userCSVGenerationsURL(params.userId);
  }
  yield call(processor.process, params);
}

export default function* init() {
  yield takeLatest(LOAD_BRANCHES_ATTEMPT, attemptLoadBranches);
  yield takeLatest(LOAD_BRANCH_RESIDENTS_ATTEMPT, attemptLoadBranchResidents);
  yield takeLatest(LOAD_CURRENT_USER_ATTEMPT, attemptLoadMe);
  yield takeLatest(LOGOUT_ATTEMPT, attemptLogout);
  yield takeLatest(LOAD_BRANCH_BEDS_ATTEMPT, attemptLoadBranchBeds);
  yield takeLatest(LOAD_RESIDENT_ATTEMPT, attemptLoadResident);
  yield takeLatest(LOAD_ORG_USERS_ATTEMPT, attemptLoadOrgUsers);
  yield takeLatest(LOAD_RESIDENTS_ATTEMPT, attemptLoadOrgResidents);
  yield takeLatest(LOAD_PRESCRIPTION_ROUTES_ATTEMPT, attemptLoadPrescriptionRoutes);
  yield takeLatest(LOAD_PRESCRIPTION_SITES_ATTEMPT, attemptLoadPrescriptionSites);
  yield takeLatest(LOAD_ORG_INACTIVE_USERS_ATTEMPT, attemptLoadOrgInactiveUsers);
  yield takeLatest(LOAD_ORG_ROLES_ATTEMPT, attemptLoadOrgRoles);
  yield takeLatest(LOAD_PRODUCTS_AND_SERVICES_ATTEMPT, attemptProductsAndServicesLoad);
  yield takeLatest(LOAD_KIN_INVOICES_ATTEMPT, attemptKinInvoicesLoad);
  yield takeLatest(LOAD_RESIDENT_RESIDENT_DETAILS_ATTEMPT, attemptResidentResidentDetailsLoad);
  yield takeLatest(LOAD_BRANCH_RESIDENT_DETAILS_ATTEMPT, attemptLoadBranchResidentDetails);
  yield takeLatest(SAVE_PDF_GENERATION_ATTEMPT, attemptPDFGeneration);
  yield takeLatest(SAVE_RESIDENT_ATTEMPT, attemptResidentSave);
  yield takeLatest(ARCHIVE_RESIDENT_ATTEMPT, attemptResidentArchive);
  yield takeLatest(DELETE_RESIDENT_ATTEMPT, attemptResidentDelete);
  yield takeLatest(LOAD_KIN_RESIDENT_PAYMENTS_ATTEMPT, attemptKinResidentPaymentsLoad);
  yield takeLatest(LOAD_LINKED_KIN_ATTEMPT, attemptLinkedKinLoad);
  yield takeLatest(LOAD_RESIDENT_CONTACTS_ATTEMPT, attemptResidentContactsLoad);
  yield takeLatest(SAVE_RESIDENT_INVOICE_ATTEMPT, attemptResidentInvoiceSave);
  yield takeLatest(SAVE_CSV_GENERATION_ATTEMPT, attemptCSVGeneration);
}
