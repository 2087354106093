import 'whatwg-fetch';

let csrfToken = '';

export function getCSRFToken() {
  return csrfToken;
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */

function checkStatus(response) {
  const responseCSRF = response.headers.get('X-CSRF');
  if (responseCSRF) csrfToken = responseCSRF;

  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  error.status = response.status;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  const allOptions = options || {};
  allOptions.credentials = 'include';
  allOptions.headers = {
    'X-CSRFToken': csrfToken,
  };
  if (!allOptions.formData && allOptions.method !== 'GET') {
    allOptions.headers['Content-Type'] = 'application/json';
  }

  return fetch(url, allOptions)
    .then(checkStatus)
    .then(parseJSON);
}
