/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Polyfills
import '@babel/polyfill';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router/immutable';

import 'sanitize.css/sanitize.css';

import ConfigProvider from 'antd/es/config-provider';
import enGB from 'antd/es/locale/en_GB';

import { loadCurrentUserAttempt } from 'containers/App/actions';
import Tracker from 'utils/tracker';

// Import root app
import App from 'containers/App';

// Import history object
import {
  history,
  store,
} from './store';

// Load the favicon, the manifest.json file and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./favicon.ico';
import './manifest.json';
import 'file-loader?name=[name].[ext]!./.htaccess';
/* eslint-enable import/no-unresolved, import/extensions */

// Import CSS reset and Global Styles
import GlobalStyle from './global-style';

const MOUNT_NODE = document.getElementById('app');

// Track all page changes
history.listen((location) => {
  if (location) Tracker.track('Page View', { path: location.pathname });
});

// Need to get CSRF Token
store.dispatch(loadCurrentUserAttempt());

// Fix Chrome passive events polluting console (Affects zooming in Victory charts).
// See https://github.com/facebook/react/issues/14856
const EVENTS_TO_MODIFY = ['touchstart', 'touchmove', 'touchend', 'touchcancel', 'wheel'];

const originalAddEventListener = document.addEventListener.bind();
document.addEventListener = (type, listener, options, wantsUntrusted) => {
  let modOptions = options;
  if (EVENTS_TO_MODIFY.includes(type)) {
    if (typeof options === 'boolean') {
      modOptions = {
        capture: options,
        passive: false,
      };
    } else if (typeof options === 'object') {
      modOptions = {
        ...options,
        passive: false,
      };
    }
  }

  return originalAddEventListener(type, listener, modOptions, wantsUntrusted);
};

const originalRemoveEventListener = document.removeEventListener.bind();
document.removeEventListener = (type, listener, options) => {
  let modOptions = options;
  if (EVENTS_TO_MODIFY.includes(type)) {
    if (typeof options === 'boolean') {
      modOptions = {
        capture: options,
        passive: false,
      };
    } else if (typeof options === 'object') {
      modOptions = {
        ...options,
        passive: false,
      };
    }
  }
  return originalRemoveEventListener(type, listener, modOptions);
};

const render = () => {
  ReactDOM.render(
    <>
      <Provider store={store}>
        <ConfigProvider locale={enGB}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </ConfigProvider>
      </Provider>
      <GlobalStyle />
    </>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

render();

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}
