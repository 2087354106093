/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import AdminDashboard from 'containers/AdminDashboard/Loadable';
import AdminSettings from 'containers/AdminSettings/Loadable';
import DeviceType from 'containers/DeviceType/Loadable';
import Devices from 'containers/Devices/Loadable';
import Device from 'containers/Device/Loadable';
import Medications from 'containers/Medications/Loadable';
import Phones from 'containers/Phones/Loadable';
import PublicDashboard from 'containers/PublicDashboard/Loadable';
import AdminOccupancy from 'containers/AdminOccupancy/Loadable';

import EmployeeDashboard from 'containers/EmployeeDashboard/Loadable';
import EmployeeSettings from 'containers/EmployeeSettings/Loadable';
import Resident from 'containers/Resident/Loadable';
import LoginPage from 'containers/LoginPage/Loadable';
import BranchSettings from 'containers/BranchSettings/Loadable';
import OrganizationSettings from 'containers/OrganizationSettings/Loadable';
import BranchReminders from 'containers/BranchReminders/Loadable';
import Event from 'containers/Event/Loadable';
import OrganizationForms from 'containers/OrganizationForms/Loadable';
import Form from 'containers/Form/Loadable';
import CompletedForm from 'containers/CompletedForm/Loadable';
import CompleteForm from 'containers/CompleteForm/Loadable';
import Branch from 'containers/Branch/Loadable';
import BranchReports from 'containers/BranchReports/Loadable';
import CompletedForms from 'containers/CompletedForms/Loadable';
import ForgotPassword from 'containers/ForgotPassword/Loadable';
import PasswordRecovery from 'containers/PasswordRecovery/Loadable';
import NewUser from 'containers/NewUser/Loadable';
import Role from 'containers/Role/Loadable';
import OrganizationProductService from 'containers/OrganizationProductService/Loadable';
import FormSearch from 'containers/FormSearch/Loadable';
import BranchWorkSchedules from 'containers/BranchWorkSchedules/Loadable';
import BranchOccupancy from 'containers/BranchOccupancy/Loadable';
import BranchProductServiceUsage from 'containers/BranchProductServiceUsage/Loadable';
import OrganizationFinance from 'containers/OrganizationFinance/Loadable';
import OutstandingBalances from 'containers/OutstandingBalances/Loadable';
import FinancesExport from 'containers/FinancesExport/Loadable';
import Invoice from 'containers/Invoice/Loadable';
import PDFWaitingArea from 'containers/PDFWaitingArea/Loadable';
import ResidentPayment from 'containers/ResidentPayment/Loadable';
import CSVWaitingArea from 'containers/CSVWaitingArea/Loadable';

import KinDashboard from 'containers/KinDashboard/Loadable';
import KinSettings from 'containers/KinSettings/Loadable';
import KinResident from 'containers/KinResident/Loadable';
import KinInvoice from 'containers/KinInvoice/Loadable';

import NotFoundPage from 'containers/NotFoundPage/Loadable';

export default function App() {
  return (
    <div>
      <Helmet
        defaultTitle="humient"
        titleTemplate="%s | humient"
      />
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />

        <Route exact path="/admin-dashboard" component={AdminDashboard} />
        <Route exact path="/admin-settings" component={AdminSettings} />
        <Route exact path="/device-types" component={DeviceType} />
        <Route exact path="/device-type/:deviceTypeId" component={Devices} />
        <Route exact path="/device/:deviceId" component={Device} />
        <Route exact path="/medications" component={Medications} />
        <Route exact path="/phones" component={Phones} />
        <Route exact path="/boards" component={PublicDashboard} />
        <Route exact path="/occupancy" component={AdminOccupancy} />

        <Route exact path="/employee-dashboard" component={EmployeeDashboard} />
        <Route exact path="/employee-settings" component={EmployeeSettings} />
        <Route path="/resident/:residentId" component={Resident} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/password-recovery/:token" component={PasswordRecovery} />
        <Route exact path="/branch/:branchId" component={Branch} />
        <Route exact path="/branch/:branchId/reports" component={BranchReports} />
        <Route exact path="/branch/:branchId/settings" component={BranchSettings} />
        <Route exact path="/branch/:branchId/reminders" component={BranchReminders} />
        <Route exact path="/branch/:branchId/work-schedules" component={BranchWorkSchedules} />
        <Route exact path="/branch/:branchId/occupancy" component={BranchOccupancy} />
        <Route exact path="/branch/:branchId/product-service-usages" component={BranchProductServiceUsage} />
        <Route exact path="/event/:eventId" component={Event} />
        <Route exact path="/organization/:organizationId/settings" component={OrganizationSettings} />
        <Route exact path="/organization/:organizationId/forms" component={OrganizationForms} />
        <Route exact path="/organization/:organizationId/products-and-services" component={OrganizationProductService} />
        <Route path="/organization/:organizationId/finance" component={OrganizationFinance} />
        <Route exact path="/organization/:organizationId/balances" component={OutstandingBalances} />
        <Route exact path="/organization/:organizationId/finances-export" component={FinancesExport} />
        <Route exact path="/completed-form/:completedFormId" component={CompletedForm} />
        <Route exact path="/form/:formId" component={Form} />
        <Route exact path="/form/:formId/new" component={CompleteForm} />
        <Route exact path="/form/:formId/completed" component={CompletedForms} />
        <Route exact path="/form/:formId/search" component={FormSearch} />
        <Route exact path="/new-user/:token" component={NewUser} />
        <Route exact path="/role/:roleId" component={Role} />
        <Route exact path="/invoice/:invoiceId" component={Invoice} />
        <Route exact path="/pdf/:pdfGenerationID" component={PDFWaitingArea} />
        <Route exact path="/payment/:residentPaymentId" component={ResidentPayment} />
        <Route exact path="/csv/:csvGenerationID" component={CSVWaitingArea} />

        <Route exact path="/kin-dashboard" component={KinDashboard} />
        <Route exact path="/kin-settings" component={KinSettings} />
        <Route path="/kin-resident/:residentId" component={KinResident} />
        <Route exact path="/kin-invoice/:invoiceId" component={KinInvoice} />

        <Route path="" component={NotFoundPage} />
      </Switch>
    </div>
  );
}
