/* eslint-disable no-undef, no-console */
class Tracker {
  constructor() {
    this.isProduction = process.env.NODE_ENV === 'production';
    this.amplitudeInstance = amplitude.getInstance();
    this.amplitudeInstance.setUserProperties({ source: 'web' });
  }

  track(eventName, props) {
    if (this.isProduction && typeof woopra !== 'undefined') {
      woopra.track(eventName, props);
      this.amplitudeInstance.logEvent(eventName, props);
    } else {
      console.log('Tracking:', eventName, props);
    }
  }

  identify(props) {
    if (this.isProduction && typeof woopra !== 'undefined') {
      woopra.identify(props);
      this.amplitudeInstance.setUserProperties(props);
      if (props.id) {
        this.amplitudeInstance.setUserId(props.id);
      }
      if (props.organization_id) {
        this.amplitudeInstance.setGroup('organization_id', props.organization_id);
      }
    } else {
      console.log('Identifying:', props);
    }
  }
}

export default new Tracker();
