import {
  LOAD_BRANCHES_ATTEMPT,
  LOAD_BRANCHES_SUCCESS,
  LOAD_BRANCHES_ERROR,
  LOAD_BRANCH_RESIDENTS_ATTEMPT,
  LOAD_BRANCH_RESIDENTS_SUCCESS,
  LOAD_BRANCH_RESIDENTS_ERROR,
  LOAD_RESIDENT_ATTEMPT,
  LOAD_RESIDENT_SUCCESS,
  LOAD_RESIDENT_ERROR,
  LOAD_CURRENT_USER_ATTEMPT,
  LOAD_CURRENT_USER_SUCCESS,
  LOAD_CURRENT_USER_ERROR,
  LOGOUT_ATTEMPT,
  CLEAR_GLOBAL_DATA,
  LOAD_BRANCH_BEDS_ATTEMPT,
  LOAD_BRANCH_BEDS_SUCCESS,
  LOAD_BRANCH_BEDS_ERROR,
  LOAD_ORG_USERS_ATTEMPT,
  LOAD_ORG_USERS_SUCCESS,
  LOAD_ORG_USERS_ERROR,
  LOAD_RESIDENTS_ATTEMPT,
  LOAD_RESIDENTS_SUCCESS,
  LOAD_RESIDENTS_ERROR,
  LOAD_PRESCRIPTION_ROUTES_ATTEMPT,
  LOAD_PRESCRIPTION_ROUTES_SUCCESS,
  LOAD_PRESCRIPTION_ROUTES_ERROR,
  LOAD_PRESCRIPTION_SITES_ATTEMPT,
  LOAD_PRESCRIPTION_SITES_SUCCESS,
  LOAD_PRESCRIPTION_SITES_ERROR,
  LOAD_ORG_INACTIVE_USERS_ATTEMPT,
  LOAD_ORG_INACTIVE_USERS_SUCCESS,
  LOAD_ORG_INACTIVE_USERS_ERROR,
  LOAD_ORG_ROLES_ATTEMPT,
  LOAD_ORG_ROLES_SUCCESS,
  LOAD_ORG_ROLES_ERROR,
  LOAD_PRODUCTS_AND_SERVICES_ATTEMPT,
  LOAD_PRODUCTS_AND_SERVICES_SUCCESS,
  LOAD_PRODUCTS_AND_SERVICES_ERROR,
  LOAD_KIN_INVOICES_ATTEMPT,
  LOAD_KIN_INVOICES_SUCCESS,
  LOAD_KIN_INVOICES_ERROR,
  LOAD_RESIDENT_RESIDENT_DETAILS_ATTEMPT,
  LOAD_RESIDENT_RESIDENT_DETAILS_SUCCESS,
  LOAD_RESIDENT_RESIDENT_DETAILS_ERROR,
  LOAD_BRANCH_RESIDENT_DETAILS_ATTEMPT,
  LOAD_BRANCH_RESIDENT_DETAILS_SUCCESS,
  LOAD_BRANCH_RESIDENT_DETAILS_ERROR,
  SAVE_PDF_GENERATION_ATTEMPT,
  SAVE_RESIDENT_ATTEMPT,
  ARCHIVE_RESIDENT_ATTEMPT,
  DELETE_RESIDENT_ATTEMPT,
  LOAD_KIN_RESIDENT_PAYMENTS_ATTEMPT,
  LOAD_KIN_RESIDENT_PAYMENTS_SUCCESS,
  LOAD_KIN_RESIDENT_PAYMENTS_ERROR,
  LOAD_LINKED_KIN_ATTEMPT,
  LOAD_LINKED_KIN_ERROR,
  LOAD_LINKED_KIN_SUCCESS,
  LOAD_RESIDENT_CONTACTS_ATTEMPT,
  LOAD_RESIDENT_CONTACTS_ERROR,
  LOAD_RESIDENT_CONTACTS_SUCCESS,
  SAVE_RESIDENT_INVOICE_ATTEMPT,
  SAVE_CSV_GENERATION_ATTEMPT,
} from './constants';

export function loadBranchesAttempt(orgId) {
  return {
    type: LOAD_BRANCHES_ATTEMPT,
    orgId,
  };
}


export function loadBranchesSuccess(data) {
  return {
    type: LOAD_BRANCHES_SUCCESS,
    data,
  };
}


export function loadBranchesError(error) {
  return {
    type: LOAD_BRANCHES_ERROR,
    error,
  };
}

export function loadBranchResidentsAttempt(branchId) {
  return {
    type: LOAD_BRANCH_RESIDENTS_ATTEMPT,
    branchId,
  };
}


export function loadBranchResidentsSuccess(branchId, data) {
  return {
    type: LOAD_BRANCH_RESIDENTS_SUCCESS,
    branchId,
    data,
  };
}


export function loadBranchResidentsError(branchId, error) {
  return {
    type: LOAD_BRANCH_RESIDENTS_ERROR,
    branchId,
    error,
  };
}

export function loadResidentsAttempt(orgId) {
  return {
    type: LOAD_RESIDENTS_ATTEMPT,
    orgId,
  };
}


export function loadResidentsSuccess(data) {
  return {
    type: LOAD_RESIDENTS_SUCCESS,
    data,
  };
}

export function loadResidentsError(error) {
  return {
    type: LOAD_RESIDENTS_ERROR,
    error,
  };
}


export function loadResidentAttempt(id) {
  return {
    type: LOAD_RESIDENT_ATTEMPT,
    id,
  };
}

export function loadResidentSuccess(id, data) {
  return {
    type: LOAD_RESIDENT_SUCCESS,
    id,
    data,
  };
}

export function loadResidentError(id, error) {
  return {
    type: LOAD_RESIDENT_ERROR,
    id,
    error,
  };
}

export function loadCurrentUserAttempt() {
  return {
    type: LOAD_CURRENT_USER_ATTEMPT,
  };
}

export function loadCurrentUserSuccess(data) {
  return {
    type: LOAD_CURRENT_USER_SUCCESS,
    data,
  };
}

export function loadCurrentUserError(error) {
  return {
    type: LOAD_CURRENT_USER_ERROR,
    error,
  };
}

export function logoutAttempt(params) {
  return {
    type: LOGOUT_ATTEMPT,
    ...params,
  };
}

export function clearGlobalData(params) {
  return {
    type: CLEAR_GLOBAL_DATA,
    ...params,
  };
}

export function loadBranchBedsAttempt(branchId) {
  return {
    type: LOAD_BRANCH_BEDS_ATTEMPT,
    branchId,
  };
}

export function loadBranchBedsError(branchId, error) {
  return {
    type: LOAD_BRANCH_BEDS_ERROR,
    branchId,
    error,
  };
}

export function loadBranchBedsSuccess(branchId, data) {
  return {
    type: LOAD_BRANCH_BEDS_SUCCESS,
    branchId,
    data,
  };
}

export function loadOrgUsersAttempt(orgId) {
  return {
    type: LOAD_ORG_USERS_ATTEMPT,
    orgId,
  };
}

export function loadOrgUsersError(error) {
  return {
    type: LOAD_ORG_USERS_ERROR,
    error,
  };
}

export function loadOrgUsersSuccess(data) {
  return {
    type: LOAD_ORG_USERS_SUCCESS,
    data,
  };
}

export function loadPrescriptionRoutesAttempt(orgId) {
  return {
    type: LOAD_PRESCRIPTION_ROUTES_ATTEMPT,
    orgId,
  };
}

export function loadPrescriptionRoutesSuccess(data) {
  return {
    type: LOAD_PRESCRIPTION_ROUTES_SUCCESS,
    data,
  };
}

export function loadPrescriptionRoutesError(error) {
  return {
    type: LOAD_PRESCRIPTION_ROUTES_ERROR,
    error,
  };
}

export function loadPresriptionSitesAttempt(orgId) {
  return {
    type: LOAD_PRESCRIPTION_SITES_ATTEMPT,
    orgId,
  };
}

export function loadPresriptionSitesSuccess(data) {
  return {
    type: LOAD_PRESCRIPTION_SITES_SUCCESS,
    data,
  };
}

export function loadPresriptionSitesError(error) {
  return {
    type: LOAD_PRESCRIPTION_SITES_ERROR,
    error,
  };
}

export function loadOrgInactiveUsersAttempt(orgId) {
  return {
    type: LOAD_ORG_INACTIVE_USERS_ATTEMPT,
    orgId,
  };
}

export function loadOrgInactiveUsersError(error) {
  return {
    type: LOAD_ORG_INACTIVE_USERS_ERROR,
    error,
  };
}

export function loadOrgInactiveUsersSuccess(data) {
  return {
    type: LOAD_ORG_INACTIVE_USERS_SUCCESS,
    data,
  };
}

export function loadOrgRolesAttempt(orgId) {
  return {
    type: LOAD_ORG_ROLES_ATTEMPT,
    orgId,
  };
}

export function loadOrgRolesError(error) {
  return {
    type: LOAD_ORG_ROLES_ERROR,
    error,
  };
}

export function loadOrgRolesSuccess(data) {
  return {
    type: LOAD_ORG_ROLES_SUCCESS,
    data,
  };
}

export function loadProductsAndServicesAttempt(orgId) {
  return {
    type: LOAD_PRODUCTS_AND_SERVICES_ATTEMPT,
    orgId,
  };
}

export function loadProductsAndServicesSuccess(data) {
  return {
    type: LOAD_PRODUCTS_AND_SERVICES_SUCCESS,
    data,
  };
}

export function loadProductsAndServicesError(error) {
  return {
    type: LOAD_PRODUCTS_AND_SERVICES_ERROR,
    error,
  };
}

export function loadKinInvoicesAttempt(kinId) {
  return {
    type: LOAD_KIN_INVOICES_ATTEMPT,
    kinId,
  };
}

export function loadKinInvoicesSuccess(data) {
  return {
    type: LOAD_KIN_INVOICES_SUCCESS,
    data,
  };
}

export function loadKinInvoicesError(error) {
  return {
    type: LOAD_KIN_INVOICES_ERROR,
    error,
  };
}

export function loadResidentResidentDetailsAttempt(params) {
  return {
    type: LOAD_RESIDENT_RESIDENT_DETAILS_ATTEMPT,
    ...params,
  };
}

export function loadResidentResidentDetailsSuccess(residentId, data) {
  return {
    type: LOAD_RESIDENT_RESIDENT_DETAILS_SUCCESS,
    residentId,
    data,
  };
}


export function loadResidentResidentDetailsError(residentId, error) {
  return {
    type: LOAD_RESIDENT_RESIDENT_DETAILS_ERROR,
    residentId,
    error,
  };
}

export function loadBranchResidentDetailsAttempt(params) {
  return {
    type: LOAD_BRANCH_RESIDENT_DETAILS_ATTEMPT,
    ...params,
  };
}

export function loadBranchResidentDetailsSuccess(branchId, data) {
  return {
    type: LOAD_BRANCH_RESIDENT_DETAILS_SUCCESS,
    branchId,
    data,
  };
}

export function loadBranchResidentDetailsError(branchId, error) {
  return {
    type: LOAD_BRANCH_RESIDENT_DETAILS_ERROR,
    branchId,
    error,
  };
}

export function savePDFGenerationAttempt(params) {
  return {
    type: SAVE_PDF_GENERATION_ATTEMPT,
    ...params,
  };
}

export function saveResidentAttempt(params) {
  return {
    type: SAVE_RESIDENT_ATTEMPT,
    ...params,
  };
}


export function archiveResidentAttempt(params) {
  return {
    type: ARCHIVE_RESIDENT_ATTEMPT,
    ...params,
  };
}

export function deleteResidentAttempt(params) {
  return {
    type: DELETE_RESIDENT_ATTEMPT,
    ...params,
  };
}

export function loadKinResidentPaymentsAttempt(kinId) {
  return {
    type: LOAD_KIN_RESIDENT_PAYMENTS_ATTEMPT,
    kinId,
  };
}

export function loadKinResidentPaymentsSuccess(data) {
  return {
    type: LOAD_KIN_RESIDENT_PAYMENTS_SUCCESS,
    data,
  };
}

export function loadKinResidentPaymentsError(error) {
  return {
    type: LOAD_KIN_RESIDENT_PAYMENTS_ERROR,
    error,
  };
}

export function loadLinkedKinAttempt(residentId) {
  return {
    type: LOAD_LINKED_KIN_ATTEMPT,
    residentId,
  };
}

export function loadLinkedKinSuccess(data, residentId) {
  return {
    type: LOAD_LINKED_KIN_SUCCESS,
    data,
    residentId,
  };
}

export function loadLinkedKinError(error, residentId) {
  return {
    type: LOAD_LINKED_KIN_ERROR,
    error,
    residentId,
  };
}

export function loadResidentContactsAttempt(residentId) {
  return {
    type: LOAD_RESIDENT_CONTACTS_ATTEMPT,
    residentId,
  };
}

export function loadResidentContactsSuccess(data, residentId) {
  return {
    type: LOAD_RESIDENT_CONTACTS_SUCCESS,
    data,
    residentId,
  };
}

export function loadResidentContactsError(error, residentId) {
  return {
    type: LOAD_RESIDENT_CONTACTS_ERROR,
    error,
    residentId,
  };
}

export function saveResidentInvoiceAttempt(params) {
  return {
    type: SAVE_RESIDENT_INVOICE_ATTEMPT,
    ...params,
  };
}

export function saveCSVGenerationAttempt(params) {
  return {
    type: SAVE_CSV_GENERATION_ATTEMPT,
    ...params,
  };
}
