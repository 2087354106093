/*
 *
 * App reducer
 *
 */

import { fromJS } from 'immutable';
import {
  LOGIN_SUCCESSFUL,
} from 'containers/LoginPage/constants';

import {
  SAVE_BRANCH_SUCCESS,
} from 'containers/BranchSettings/constants';

import {
  LOAD_BRANCHES_SUCCESS,
  LOAD_BRANCH_RESIDENTS_ATTEMPT,
  LOAD_BRANCH_RESIDENTS_SUCCESS,
  LOAD_BRANCH_RESIDENTS_ERROR,
  LOAD_CURRENT_USER_SUCCESS,
  LOAD_CURRENT_USER_ERROR,
  CLEAR_GLOBAL_DATA,
  LOAD_BRANCH_BEDS_ATTEMPT,
  LOAD_BRANCH_BEDS_SUCCESS,
  LOAD_BRANCH_BEDS_ERROR,
  LOAD_RESIDENT_ATTEMPT,
  LOAD_RESIDENT_SUCCESS,
  LOAD_RESIDENT_ERROR,
  LOAD_ORG_USERS_ATTEMPT,
  LOAD_ORG_USERS_SUCCESS,
  LOAD_ORG_USERS_ERROR,
  LOAD_RESIDENTS_SUCCESS,
  LOAD_PRESCRIPTION_ROUTES_ATTEMPT,
  LOAD_PRESCRIPTION_ROUTES_SUCCESS,
  LOAD_PRESCRIPTION_ROUTES_ERROR,
  LOAD_PRESCRIPTION_SITES_ATTEMPT,
  LOAD_PRESCRIPTION_SITES_SUCCESS,
  LOAD_PRESCRIPTION_SITES_ERROR,
  LOAD_ORG_INACTIVE_USERS_ATTEMPT,
  LOAD_ORG_INACTIVE_USERS_SUCCESS,
  LOAD_ORG_INACTIVE_USERS_ERROR,
  LOAD_ORG_ROLES_ATTEMPT,
  LOAD_ORG_ROLES_SUCCESS,
  LOAD_ORG_ROLES_ERROR,
  LOAD_PRODUCTS_AND_SERVICES_ATTEMPT,
  LOAD_PRODUCTS_AND_SERVICES_SUCCESS,
  LOAD_PRODUCTS_AND_SERVICES_ERROR,
  LOAD_KIN_INVOICES_ATTEMPT,
  LOAD_KIN_INVOICES_SUCCESS,
  LOAD_KIN_INVOICES_ERROR,
  LOAD_RESIDENT_RESIDENT_DETAILS_ATTEMPT,
  LOAD_RESIDENT_RESIDENT_DETAILS_SUCCESS,
  LOAD_RESIDENT_RESIDENT_DETAILS_ERROR,
  LOAD_BRANCH_RESIDENT_DETAILS_ATTEMPT,
  LOAD_BRANCH_RESIDENT_DETAILS_ERROR,
  LOAD_BRANCH_RESIDENT_DETAILS_SUCCESS,
  LOAD_KIN_RESIDENT_PAYMENTS_ATTEMPT,
  LOAD_KIN_RESIDENT_PAYMENTS_SUCCESS,
  LOAD_KIN_RESIDENT_PAYMENTS_ERROR,
  LOAD_RESIDENT_CONTACTS_ATTEMPT,
  LOAD_RESIDENT_CONTACTS_SUCCESS,
  LOAD_RESIDENT_CONTACTS_ERROR,
  LOAD_LINKED_KIN_ATTEMPT,
  LOAD_LINKED_KIN_SUCCESS,
  LOAD_LINKED_KIN_ERROR,
} from './constants';

const initialState = fromJS({
  branches: {},
  users: {},
  loadedBranches: false,
  loadedResidents: false,
  residents: {},
  currentUser: {},
  checkedMeAuth: false,
  organization: {},
  beds: {},
  branchBeds: {},
  prescriptionRoutes: {
    data: [],
  },
  prescriptionSites: {
    data: [],
  },
  inactiveUsers: {},
  roles: {},
  productsAndServices: {},
  kinInvoices: {},
  kinResidentPayments: {},
  residentDetails: {
    data: {},
    loading: false,
  },
  branchResidentDetails: {
    data: {},
    loading: false,
  },
  linkedKin: {
    data: {},
    loading: false,
  },
  contacts: {
    data: {},
    loading: false,
  },
});


function createBranchesState(response) {
  const branchesState = {};
  for (let i = 0; i < response.length; i += 1) {
    branchesState[response[i].id.toString()] = response[i];
  }
  return fromJS(branchesState);
}

function createUsersState(response) {
  const usersState = {};
  for (let i = 0; i < response.length; i += 1) {
    usersState[response[i].id] = response[i];
  }
  return fromJS(usersState);
}

function splitOrgAndUserState(response, state) {
  const responseCopy = Object.assign({}, response);
  const nextState = state.set('organization', fromJS(responseCopy.organization));
  delete responseCopy.organization;
  return nextState.set('currentUser', fromJS(responseCopy));
}

function createBranchBedsState(branchId, response, currentState) {
  const branchBedIds = [];
  let nextState = currentState;
  for (let i = 0; i < response.length; i += 1) {
    nextState = nextState.setIn(['beds', response[i].id.toString()], fromJS(response[i]));
    branchBedIds.push(response[i].id);
  }
  return nextState.setIn(['branchBeds', branchId.toString(), 'data'], fromJS(branchBedIds));
}

function createBranchResidentsState(branchId, response, currentState) {
  const branchResidentsIds = [];
  let nextState = currentState;
  for (let i = 0; i < response.length; i += 1) {
    const residentId = response[i].id.toString();
    if (nextState.getIn(['residents', residentId])) {
      nextState = nextState.mergeIn(['residents', residentId, 'data'], fromJS(response[i]));
    } else {
      nextState = nextState.setIn(['residents', residentId, 'available'], true);
      nextState = nextState.setIn(['residents', residentId, 'data'], fromJS(response[i]));
    }
    branchResidentsIds.push(response[i].id);
  }
  return nextState.setIn(['branchResidents', branchId.toString(), 'data'], fromJS(branchResidentsIds));
}

function createResidentsState(response, currentState) {
  const branchesResidents = {};
  let nextState = currentState;
  for (let i = 0; i < response.length; i += 1) {
    const resident = response[i];
    const residentId = resident.id.toString();
    if (nextState.getIn(['residents', residentId])) {
      nextState = nextState.mergeIn(['residents', residentId, 'data'], fromJS(resident));
    } else {
      nextState = nextState.setIn(['residents', residentId, 'available'], true);
      nextState = nextState.setIn(['residents', residentId, 'data'], fromJS(resident));
    }
    if (branchesResidents[resident.branch_id]) {
      branchesResidents[resident.branch_id].push(resident.id);
    } else {
      branchesResidents[resident.branch_id] = [resident.id];
    }
  }
  Object.keys(branchesResidents).map((id) => {
    const branchId = id.toString();
    nextState = nextState.setIn(['branchResidents', branchId, 'data'], fromJS(branchesResidents[id]));
    nextState = nextState.setIn(['branchResidents', branchId, 'available'], true);
    return true;
  });
  return nextState;
}

function globalReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_CURRENT_USER_SUCCESS: {
      const newState = state.set('checkedMeAuth', true);
      if (action.data.email) {
        return splitOrgAndUserState(action.data, newState);
      }
      return newState;
    }
    case LOGIN_SUCCESSFUL: {
      return splitOrgAndUserState(action.data, state);
    }
    case LOAD_CURRENT_USER_ERROR: {
      return state.set('checkedMeAuth', true);
    }
    case LOAD_BRANCHES_SUCCESS: {
      return state.set('branches', createBranchesState(action.data))
        .set('loadedBranches', true);
    }
    case LOAD_BRANCH_RESIDENTS_ATTEMPT: {
      return state.setIn(['branchResidents', action.branchId.toString(), 'loading'], true)
        .setIn(['branchResidents', action.branchId.toString(), 'error'], false);
    }
    case LOAD_BRANCH_RESIDENTS_SUCCESS: {
      const nextState = state.setIn(['branchResidents', action.branchId.toString(), 'loading'], false)
        .setIn(['branchResidents', action.branchId.toString(), 'available'], true);
      return createBranchResidentsState(action.branchId, action.data, nextState);
    }
    case LOAD_BRANCH_RESIDENTS_ERROR: {
      return state.setIn(['branchResidents', action.branchId.toString(), 'loading'], false)
        .setIn(['branchResidents', action.branchId.toString(), 'error'], action.error);
    }
    case LOAD_RESIDENTS_SUCCESS: {
      const nextState = state.set('loadedResidents', true);
      return createResidentsState(action.data, nextState);
    }
    case SAVE_BRANCH_SUCCESS: {
      return state.setIn(['branches', action.data.id], fromJS(action.data));
    }
    case CLEAR_GLOBAL_DATA: {
      return initialState.set('checkedMeAuth', true);
    }
    case LOAD_BRANCH_BEDS_ATTEMPT: {
      return state.setIn(['branchBeds', action.branchId.toString(), 'loading'], true)
        .setIn(['branchBeds', action.branchId.toString(), 'error'], false);
    }
    case LOAD_BRANCH_BEDS_SUCCESS: {
      const nextState = state.setIn(['branchBeds', action.branchId.toString(), 'loading'], false)
        .setIn(['branchBeds', action.branchId.toString(), 'available'], true);
      return createBranchBedsState(action.branchId, action.data, nextState);
    }
    case LOAD_BRANCH_BEDS_ERROR: {
      return state.setIn(['branchBeds', action.branchId.toString(), 'loading'], false)
        .setIn(['branchBeds', action.branchId.toString(), 'error'], action.error);
    }
    case LOAD_RESIDENT_ATTEMPT: {
      return state.setIn(['residents', action.id.toString(), 'loading'], true)
        .setIn(['residents', action.id.toString(), 'error'], false);
    }
    case LOAD_RESIDENT_SUCCESS: {
      return state.setIn(['residents', action.id.toString(), 'loading'], false)
        .setIn(['residents', action.id.toString(), 'available'], true)
        .setIn(['residents', action.id.toString(), 'data'], fromJS(action.data));
    }
    case LOAD_RESIDENT_ERROR: {
      return state.setIn(['residents', action.id.toString(), 'loading'], false)
        .setIn(['residents', action.id.toString(), 'error'], action.error);
    }
    case LOAD_ORG_USERS_ATTEMPT: {
      return state.setIn(['users', 'loading'], true)
        .setIn(['users', 'error'], false);
    }
    case LOAD_ORG_USERS_SUCCESS: {
      return state.setIn(['users', 'loading'], false)
        .setIn(['users', 'available'], true)
        .setIn(['users', 'data'], createUsersState(action.data));
    }
    case LOAD_ORG_USERS_ERROR: {
      return state.setIn(['users', 'loading'], false)
        .setIn(['users', 'error'], action.error);
    }
    case LOAD_PRESCRIPTION_ROUTES_ATTEMPT: {
      return state.setIn(['prescriptionRoutes', 'loading'], true)
        .setIn(['prescriptionRoutes', 'error'], false);
    }
    case LOAD_PRESCRIPTION_ROUTES_SUCCESS: {
      return state.setIn(['prescriptionRoutes', 'loading'], false)
        .setIn(['prescriptionRoutes', 'available'], true)
        .setIn(['prescriptionRoutes', 'data'], action.data);
    }
    case LOAD_PRESCRIPTION_ROUTES_ERROR: {
      return state.setIn(['prescriptionRoutes', 'loading'], false)
        .setIn(['prescriptionRoutes', 'error'], action.error);
    }
    case LOAD_PRESCRIPTION_SITES_ATTEMPT: {
      return state.setIn(['prescriptionSites', 'loading'], true)
        .setIn(['prescriptionSites', 'error'], false);
    }
    case LOAD_PRESCRIPTION_SITES_SUCCESS: {
      return state.setIn(['prescriptionSites', 'loading'], false)
        .setIn(['prescriptionSites', 'available'], true)
        .setIn(['prescriptionSites', 'data'], action.data);
    }
    case LOAD_PRESCRIPTION_SITES_ERROR: {
      return state.setIn(['prescriptionSites', 'loading'], false)
        .setIn(['prescriptionSites', 'error'], action.error);
    }
    case LOAD_ORG_INACTIVE_USERS_ATTEMPT: {
      return state.setIn(['inactiveUsers', 'loading'], true)
        .setIn(['inactiveUsers', 'error'], false);
    }
    case LOAD_ORG_INACTIVE_USERS_SUCCESS: {
      return state.setIn(['inactiveUsers', 'loading'], false)
        .setIn(['inactiveUsers', 'available'], true)
        .setIn(['inactiveUsers', 'data'], createUsersState(action.data));
    }
    case LOAD_ORG_INACTIVE_USERS_ERROR: {
      return state.setIn(['inactiveUsers', 'loading'], false)
        .setIn(['inactiveUsers', 'error'], action.error);
    }
    case LOAD_ORG_ROLES_ATTEMPT: {
      return state.setIn(['roles', 'loading'], true)
        .setIn(['roles', 'error'], false);
    }
    case LOAD_ORG_ROLES_SUCCESS: {
      return state.setIn(['roles', 'loading'], false)
        .setIn(['roles', 'available'], true)
        .setIn(['roles', 'data'], createUsersState(action.data));
    }
    case LOAD_ORG_ROLES_ERROR: {
      return state.setIn(['roles', 'loading'], false)
        .setIn(['roles', 'error'], action.error);
    }
    case LOAD_PRODUCTS_AND_SERVICES_ATTEMPT:
      return state.setIn(['productsAndServices', 'error'], false)
        .setIn(['productsAndServices', 'loading'], true);
    case LOAD_PRODUCTS_AND_SERVICES_SUCCESS:
      return state.setIn(['productsAndServices', 'data'], action.data)
        .setIn(['productsAndServices', 'loading'], false)
        .setIn(['productsAndServices', 'available'], true);
    case LOAD_PRODUCTS_AND_SERVICES_ERROR:
      return state.setIn(['productsAndServices', 'error'], action.error)
        .setIn(['productsAndServices', 'loading'], false);
    case LOAD_KIN_INVOICES_ATTEMPT:
      return state.setIn(['kinInvoices', 'error'], false)
        .setIn(['kinInvoices', 'loading'], true);
    case LOAD_KIN_INVOICES_SUCCESS:
      return state.setIn(['kinInvoices', 'data'], action.data)
        .setIn(['kinInvoices', 'loading'], false)
        .setIn(['kinInvoices', 'available'], true);
    case LOAD_KIN_INVOICES_ERROR:
      return state.setIn(['kinInvoices', 'error'], action.error)
        .setIn(['kinInvoices', 'loading'], false);
    case LOAD_RESIDENT_RESIDENT_DETAILS_ATTEMPT:
      return state.setIn(['residentDetails', 'error', action.residentId.toString()], false)
        .setIn(['residentDetails', 'loading'], true);
    case LOAD_RESIDENT_RESIDENT_DETAILS_SUCCESS:
      return state
        .setIn(['residentDetails', 'data', action.residentId.toString()], fromJS({
          data: action.data,
          available: true,
        }))
        .setIn(['residentDetails', 'loading'], false);
    case LOAD_RESIDENT_RESIDENT_DETAILS_ERROR:
      return state
        .setIn(['residentDetails', 'error', action.residentId.toString()], action.error)
        .setIn(['residentDetails', 'loading'], false);
    case LOAD_BRANCH_RESIDENT_DETAILS_ATTEMPT:
      return state.setIn(['branchResidentDetails', 'error', action.branchId.toString()], false)
        .setIn(['branchResidentDetails', 'loading'], true);
    case LOAD_BRANCH_RESIDENT_DETAILS_SUCCESS:
      return state
        .setIn(['branchResidentDetails', 'data', action.branchId.toString()], fromJS({
          data: action.data,
          available: true,
        }))
        .setIn(['branchResidentDetails', 'loading'], false);
    case LOAD_BRANCH_RESIDENT_DETAILS_ERROR:
      return state
        .setIn(['branchResidentDetails', 'error', action.branchId.toString()], action.error)
        .setIn(['branchResidentDetails', 'loading'], false);
    case LOAD_KIN_RESIDENT_PAYMENTS_ATTEMPT:
      return state.setIn(['kinResidentPayments', 'error'], false)
        .setIn(['kinResidentPayments', 'loading'], true);
    case LOAD_KIN_RESIDENT_PAYMENTS_SUCCESS:
      return state.setIn(['kinResidentPayments', 'data'], action.data)
        .setIn(['kinResidentPayments', 'loading'], false)
        .setIn(['kinResidentPayments', 'available'], true);
    case LOAD_KIN_RESIDENT_PAYMENTS_ERROR:
      return state.setIn(['kinResidentPayments', 'error'], action.error)
        .setIn(['kinResidentPayments', 'loading'], false);
    case LOAD_LINKED_KIN_ATTEMPT:
      return state.setIn(['linkedKin', 'loading'], true)
        .setIn(['linkedKin', 'data', action.residentId.toString(), 'error'], false);
    case LOAD_LINKED_KIN_SUCCESS:
      return state.setIn(['linkedKin', 'data', action.residentId.toString(), 'data'], action.data)
        .setIn(['linkedKin', 'loading'], false);
    case LOAD_LINKED_KIN_ERROR:
      return state.setIn(['linkedKin', 'data', action.residentId.toString(), 'error'], action.error)
        .setIn(['linkedKin', 'loading'], false);
    case LOAD_RESIDENT_CONTACTS_ATTEMPT:
      return state.setIn(['contacts', 'loading'], true)
        .setIn(['contacts', 'data', action.residentId.toString(), 'error'], false);
    case LOAD_RESIDENT_CONTACTS_SUCCESS:
      return state.setIn(['contacts', 'data', action.residentId.toString(), 'data'], action.data)
        .setIn(['contacts', 'loading'], false);
    case LOAD_RESIDENT_CONTACTS_ERROR:
      return state.setIn(['contacts', 'data', action.residentId.toString(), 'error'], action.error)
        .setIn(['contacts', 'loading'], false);
    default: {
      return state;
    }
  }
}

export default globalReducer;
