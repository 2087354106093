/*
 *
 * KinNavigation reducer
 *
 */

import { fromJS } from 'immutable';

import {
  LOAD_KIN_RESIDENTS_ATTEMPT,
  LOAD_KIN_RESIDENTS_SUCCESS,
  LOAD_KIN_RESIDENTS_ERROR,
} from './constants';

const initialState = fromJS({
  residents: {
    data: [],
    loading: false,
    available: false,
    error: false,
  },
});

function kinNavigationReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_KIN_RESIDENTS_ATTEMPT:
      return state.setIn(['residents', 'error'], false)
        .setIn(['residents', 'loading'], true);
    case LOAD_KIN_RESIDENTS_SUCCESS:
      return state.setIn(['residents', 'data'], action.data)
        .setIn(['residents', 'loading'], false)
        .setIn(['residents', 'available'], true);
    case LOAD_KIN_RESIDENTS_ERROR:
      return state.setIn(['residents', 'error'], action.error)
        .setIn(['residents', 'loading'], false);
    default:
      return state;
  }
}

export default kinNavigationReducer;
