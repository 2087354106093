/**
 * Asynchronously loads the component for ForgotPassword
 */
import Loadable from 'react-loadable';
import LoadingPage from 'components/LoadingPage';

export default Loadable({
  loader: () => import('./index'),
  loading: LoadingPage,
});
