/*
 *
 * EmployeeNavigation reducer
 *
 */

import { fromJS } from 'immutable';
import {
  SET_OPEN_KEYS,
  SET_SECONDARY_NAV,
  SET_RESIDENT_NAV_BRANCH,
} from './constants';

const initialState = fromJS({
  showSecondaryNav: false,
  openKeys: [],
  residentNavBranchId: false,
});

function employeeNavigationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_OPEN_KEYS:
      return state.set('openKeys', fromJS(action.keys));
    case SET_SECONDARY_NAV:
      return state.set('showSecondaryNav', action.value);
    case SET_RESIDENT_NAV_BRANCH:
      return state.set('residentNavBranchId', action.id);
    default:
      return state;
  }
}

export default employeeNavigationReducer;
