const colors = {
  yellow1: '#FFF3CF',
  red1: '#FFD6D3',
  red2: '#F98F8F',
  red3: '#FF0000',
  red4: '#BB0606',
  green1: '#CFEFDF',
  green2: '#90F9C3',
  green3: '#37C916',
  blue1: '#EDF3FF',
  blue2: '#5A6AE0',
  blue3: '#263BD6',
  blue4: '#2231B9',
  grey1: '#EAECEF',
  grey2: '#D9D9D9',
  grey3: '#999999',
  grey4: '#666666',
  white1: '#FFFFFF',
  black1: '#000000',
  antd_border: '#E8E8E8',
  antd_faded_text: 'rgba(0, 0, 0, 0.25)',
  geekblue: '#2F54EB',
  blood_pressure: '#B9EDE0',
  heart_rate: '#F58231',
  spo2: '#911EB4',
  respiration_rate: '#46F0F0',
  respiration: '#46F0F0',
  weight: '#D2F53C',
  glucose: '#008080',
  temperature: '#808000',
};

export default colors;
