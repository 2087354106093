import { Map as ImmutableMap } from 'immutable';

import history from 'utils/history';

import configureStore from './configureStore';

const initialState = ImmutableMap();

const store = configureStore(initialState, history);

export {
  store,
  history,
};
