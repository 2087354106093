/*
 *
 * BranchSettings constants
 *
 */

export const SAVE_BRANCH_ATTEMPT = 'app/BranchSettings/SAVE_BRANCH_ATTEMPT';
export const SAVE_BRANCH_SUCCESS = 'app/BranchSettings/SAVE_BRANCH_SUCCESS';

export const LOAD_BRANCH_ARCHIVED_RESIDENTS_ATTEMPT = 'app/BranchSettings/LOAD_BRANCH_ARCHIVED_RESIDENTS_ATTEMPT';
export const LOAD_BRANCH_ARCHIVED_RESIDENTS_SUCCESS = 'app/BranchSettings/LOAD_BRANCH_ARCHIVED_RESIDENTS_SUCCESS';
export const LOAD_BRANCH_ARCHIVED_RESIDENTS_ERROR = 'app/BranchSettings/LOAD_BRANCH_ARCHIVED_RESIDENTS_ERROR';

export const DELETE_RESIDENT_ATTEMPT = 'app/BranchSettings/DELETE_RESIDENT_ATTEMPT';

export const UNARCHIVE_RESIDENT_ATTEMPT = 'app/BranchSettings/UNARCHIVE_RESIDENT_ATTEMPT';

export const LOAD_BRANCH_VITALS_REQUIREMENTS_ATTEMPT = 'app/BranchSettings/LOAD_BRANCH_VITALS_REQUIREMENTS_ATTEMPT';
export const LOAD_BRANCH_VITALS_REQUIREMENTS_SUCCESS = 'app/BranchSettings/LOAD_BRANCH_VITALS_REQUIREMENTS_SUCCESS';
export const LOAD_BRANCH_VITALS_REQUIREMENTS_ERROR = 'app/BranchSettings/LOAD_BRANCH_VITALS_REQUIREMENTS_ERROR';

export const SAVE_BRANCH_VITALS_REQUIREMENTS_ATTEMPT = 'app/BranchSettings/SAVE_BRANCH_VITALS_REQUIREMENTS_ATTEMPT';

export const LOAD_VITALS_REPORT_SUBSCRIPTIONS_ATTEMPT = 'app/BranchSettings/LOAD_VITALS_REPORT_SUBSCRIPTIONS_ATTEMPT';
export const LOAD_VITALS_REPORT_SUBSCRIPTIONS_SUCCESS = 'app/BranchSettings/LOAD_VITALS_REPORT_SUBSCRIPTIONS_SUCCESS';
export const LOAD_VITALS_REPORT_SUBSCRIPTIONS_ERROR = 'app/BranchSettings/LOAD_VITALS_REPORT_SUBSCRIPTIONS_ERROR';

export const SAVE_VITALS_REPORT_SUBSCRIPTIONS_ATTEMPT = 'app/BranchSettings/SAVE_VITALS_REPORT_SUBSCRIPTIONS_ATTEMPT';

export const SAVE_BRANCH_RESIDENT_DETAILS_ATTEMPT = 'app/BranchSettings/SAVE_BRANCH_RESIDENT_DETAILS_ATTEMPT';

export const LOAD_PUSH_SUBSCRIPTIONS_ATTEMPT = 'app/BranchSettings/LOAD_PUSH_SUBSCRIPTIONS_ATTEMPT';
export const LOAD_PUSH_SUBSCRIPTIONS_SUCCESS = 'app/BranchSettings/LOAD_PUSH_SUBSCRIPTIONS_SUCCESS';
export const LOAD_PUSH_SUBSCRIPTIONS_ERROR = 'app/BranchSettings/LOAD_PUSH_SUBSCRIPTIONS_ERROR';

export const SAVE_PUSH_SUBSCRIPTIONS_ATTEMPT = 'app/BranchSettings/SAVE_PUSH_SUBSCRIPTIONS_ATTEMPT';

export const LOAD_BRANCH_INVOICE_DETAILS_ATTEMPT = 'app/BranchSettings/LOAD_BRANCH_INVOICE_DETAILS_ATTEMPT';
export const LOAD_BRANCH_INVOICE_DETAILS_SUCCESS = 'app/BranchSettings/LOAD_BRANCH_INVOICE_DETAILS_SUCCESS';
export const LOAD_BRANCH_INVOICE_DETAILS_ERROR = 'app/BranchSettings/LOAD_BRANCH_INVOICE_DETAILS_ERROR';

export const SAVE_BRANCH_INVOICE_DETAILS_ATTEMPT = 'app/BranchSettings/SAVE_BRANCH_INVOICE_DETAILS_ATTEMPT';
