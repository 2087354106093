/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const DEFAULT_LOCALE = 'en';

export const LOAD_CURRENT_USER_ATTEMPT = 'app/LOAD_CURRENT_USER_ATTEMPT';
export const LOAD_CURRENT_USER_SUCCESS = 'app/LOAD_CURRENT_USER_SUCCESS';
export const LOAD_CURRENT_USER_ERROR = 'app/LOAD_CURRENT_USER_ERROR';

export const LOAD_BRANCHES_ATTEMPT = 'app/LOAD_BRANCHES_ATTEMPT';
export const LOAD_BRANCHES_SUCCESS = 'app/LOAD_BRANCHES_SUCCESS';
export const LOAD_BRANCHES_ERROR = 'app/LOAD_BRANCHES_ERROR';

export const LOAD_RESIDENTS_ATTEMPT = 'app/LOAD_RESIDENTS_ATTEMPT';
export const LOAD_RESIDENTS_SUCCESS = 'app/LOAD_RESIDENTS_SUCCESS';
export const LOAD_RESIDENTS_ERROR = 'app/LOAD_RESIDENTS_ERROR';

export const LOAD_BRANCH_RESIDENTS_ATTEMPT = 'app/LOAD_BRANCH_RESIDENTS_ATTEMPT';
export const LOAD_BRANCH_RESIDENTS_SUCCESS = 'app/LOAD_BRANCH_RESIDENTS_SUCCESS';
export const LOAD_BRANCH_RESIDENTS_ERROR = 'app/LOAD_BRANCH_RESIDENTS_ERROR';

export const LOAD_RESIDENT_ATTEMPT = 'app/LOAD_RESIDENT_ATTEMPT';
export const LOAD_RESIDENT_SUCCESS = 'app/LOAD_RESIDENT_SUCCESS';
export const LOAD_RESIDENT_ERROR = 'app/LOAD_RESIDENT_ERROR';

export const LOAD_BRANCH_BEDS_ATTEMPT = 'app/LOAD_BRANCH_BEDS_ATTEMPT';
export const LOAD_BRANCH_BEDS_SUCCESS = 'app/LOAD_BRANCH_BEDS_SUCCESS';
export const LOAD_BRANCH_BEDS_ERROR = 'app/LOAD_BRANCH_BEDS_ERROR';

export const LOAD_ORG_USERS_ATTEMPT = 'app/LOAD_ORG_USERS_ATTEMPT';
export const LOAD_ORG_USERS_SUCCESS = 'app/LOAD_ORG_USERS_SUCCESS';
export const LOAD_ORG_USERS_ERROR = 'app/LOAD_ORG_USERS_ERROR';

export const LOGOUT_ATTEMPT = 'app/LOGOUT_ATTEMPT';

export const CLEAR_GLOBAL_DATA = 'app/CLEAR_GLOBAL_DATA';

export const LOAD_PRESCRIPTION_ROUTES_ATTEMPT = 'app/LOAD_PRESCRIPTION_ROUTES_ATTEMPT';
export const LOAD_PRESCRIPTION_ROUTES_SUCCESS = 'app/LOAD_PRESCRIPTION_ROUTES_SUCCESS';
export const LOAD_PRESCRIPTION_ROUTES_ERROR = 'app/LOAD_PRESCRIPTION_ROUTES_ERROR';

export const LOAD_PRESCRIPTION_SITES_ATTEMPT = 'app/LOAD_PRESCRIPTION_SITES_ATTEMPT';
export const LOAD_PRESCRIPTION_SITES_SUCCESS = 'app/LOAD_PRESCRIPTION_SITES_SUCCESS';
export const LOAD_PRESCRIPTION_SITES_ERROR = 'app/LOAD_PRESCRIPTION_SITES_ERROR';

export const LOAD_ORG_INACTIVE_USERS_ATTEMPT = 'app/LOAD_ORG_INACTIVE_USERS_ATTEMPT';
export const LOAD_ORG_INACTIVE_USERS_SUCCESS = 'app/LOAD_ORG_INACTIVE_USERS_SUCCESS';
export const LOAD_ORG_INACTIVE_USERS_ERROR = 'app/LOAD_ORG_INACTIVE_USERS_ERROR';

export const LOAD_ORG_ROLES_ATTEMPT = 'app/LOAD_ORG_ROLES_ATTEMPT';
export const LOAD_ORG_ROLES_SUCCESS = 'app/LOAD_ORG_ROLES_SUCCESS';
export const LOAD_ORG_ROLES_ERROR = 'app/LOAD_ORG_ROLES_ERROR';

export const LOAD_PRODUCTS_AND_SERVICES_ATTEMPT = 'app/LOAD_PRODUCTS_AND_SERVICES_ATTEMPT';
export const LOAD_PRODUCTS_AND_SERVICES_SUCCESS = 'app/LOAD_PRODUCTS_AND_SERVICES_SUCCESS';
export const LOAD_PRODUCTS_AND_SERVICES_ERROR = 'app/LOAD_PRODUCTS_AND_SERVICES_ERROR';

export const LOAD_KIN_INVOICES_ATTEMPT = 'app/LOAD_KIN_INVOICES_ATTEMPT';
export const LOAD_KIN_INVOICES_SUCCESS = 'app/LOAD_KIN_INVOICES_SUCCESS';
export const LOAD_KIN_INVOICES_ERROR = 'app/LOAD_KIN_INVOICES_ERROR';

export const LOAD_RESIDENT_RESIDENT_DETAILS_ATTEMPT = 'app/LOAD_RESIDENT_RESIDENT_DETAILS_ATTEMPT';
export const LOAD_RESIDENT_RESIDENT_DETAILS_SUCCESS = 'app/LOAD_RESIDENT_RESIDENT_DETAILS_SUCCESS';
export const LOAD_RESIDENT_RESIDENT_DETAILS_ERROR = 'app/LOAD_RESIDENT_RESIDENT_DETAILS_ERROR';

export const LOAD_BRANCH_RESIDENT_DETAILS_ATTEMPT = 'app/LOAD_BRANCH_RESIDENT_DETAILS_ATTEMPT';
export const LOAD_BRANCH_RESIDENT_DETAILS_SUCCESS = 'app/LOAD_BRANCH_RESIDENT_DETAILS_SUCCESS';
export const LOAD_BRANCH_RESIDENT_DETAILS_ERROR = 'app/LOAD_BRANCH_RESIDENT_DETAILS_ERROR';

export const SAVE_PDF_GENERATION_ATTEMPT = 'app/SAVE_PDF_GENERATION_ATTEMPT';

export const SAVE_RESIDENT_ATTEMPT = 'app/SAVE_RESIDENT_ATTEMPT';
export const ARCHIVE_RESIDENT_ATTEMPT = 'app/ARCHIVE_RESIDENT_ATTEMPT';
export const DELETE_RESIDENT_ATTEMPT = 'app/DELETE_RESIDENT_ATTEMPT';

export const LOAD_KIN_RESIDENT_PAYMENTS_ATTEMPT = 'app/LOAD_KIN_RESIDENT_PAYMENTS_ATTEMPT';
export const LOAD_KIN_RESIDENT_PAYMENTS_SUCCESS = 'app/LOAD_KIN_RESIDENT_PAYMENTS_SUCCESS';
export const LOAD_KIN_RESIDENT_PAYMENTS_ERROR = 'app/LOAD_KIN_RESIDENT_PAYMENTS_ERROR';

export const LOAD_LINKED_KIN_ATTEMPT = 'app/LOAD_LINKED_KIN_ATTEMPT';
export const LOAD_LINKED_KIN_SUCCESS = 'app/LOAD_LINKED_KIN_SUCCESS';
export const LOAD_LINKED_KIN_ERROR = 'app/LOAD_LINKED_KIN_ERROR';

export const LOAD_RESIDENT_CONTACTS_ATTEMPT = 'app/LOAD_RESIDENT_CONTACTS_ATTEMPT';
export const LOAD_RESIDENT_CONTACTS_SUCCESS = 'app/LOAD_RESIDENT_CONTACTS_SUCCESS';
export const LOAD_RESIDENT_CONTACTS_ERROR = 'app/LOAD_RESIDENT_CONTACTS_ERROR';

export const SAVE_RESIDENT_INVOICE_ATTEMPT = 'app/SAVE_RESIDENT_INVOICE_ATTEMPT';

export const SAVE_CSV_GENERATION_ATTEMPT = 'app/SAVE_CSV_GENERATION_ATTEMPT';
