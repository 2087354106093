/*
 *
 * EmployeeNavigation constants
 *
 */

export const SET_OPEN_KEYS = 'app/EmployeeNavigation/SET_OPEN_KEYS';
export const SET_SELECTED_KEYS = 'app/EmployeeNavigation/SET_SELECTED_KEYS';
export const SET_SECONDARY_NAV = 'app/EmployeeNavigation/SET_SECONDARY_NAV';
export const SET_RESIDENT_NAV_BRANCH = 'app/EmployeeNavigation/SET_RESIDENT_NAV_BRANCH';
