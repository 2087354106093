/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { reducer as reduxFormReducer } from 'redux-form/immutable';

import globalReducer from 'containers/App/reducer';
import residentsNavReducer from 'containers/ResidentsNav/reducer';
import employeeNavigationReducer from 'containers/EmployeeNavigation/reducer';
import kinNavigationReducer from 'containers/KinNavigation/reducer';

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers = {}) {
  return history => combineReducers({
    router: connectRouter(history),
    form: reduxFormReducer,
    global: globalReducer,
    residentsNav: residentsNavReducer,
    employeeNavigation: employeeNavigationReducer,
    kinNavigation: kinNavigationReducer,
    ...injectedReducers,
  });
}
