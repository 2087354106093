import { call, put } from 'redux-saga/effects';
import { startSubmit, stopSubmit, reset } from 'redux-form/immutable';

import request from 'utils/request';
import { ME_URL } from 'utils/urlGenerators';

const GENERIC_ERROR_MESSAGE = 'Server Error. Please try again later.';
const MAX_RETRIES = 3;

function generateGetParams(params) {
  return Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');
}

function flattenErrors(errorObj) {
  const fieldErrors = {};
  Object.keys(errorObj).forEach((field) => {
    const errors = errorObj[field];
    // Check for nested forms and recurse if necessary
    if (errors.constructor === Array) {
      [fieldErrors[field]] = errors;
    } else {
      fieldErrors[field] = flattenErrors(errors);
    }
  });
  return fieldErrors;
}

function transformToFormErrors(response) {
  if (response.error) {
    return { _error: response.error };
  }
  if (response.message) {
    return { _error: response.message };
  }
  if (response.errors) {
    return flattenErrors(response.errors);
  }
  return { _error: GENERIC_ERROR_MESSAGE };
}

class RequestProcessor {
  * process(params) {
    const {
      method,
      url,
      data,
      submitAsFormData,
      formName,
      successCallback,
      failureCallback,
      successAction,
      failureAction,
      resetForm,
      generatorCallback,
      retriesLeft = MAX_RETRIES,
    } = params;

    const options = { method };

    if (['PUT', 'POST'].includes(method)) {
      if (submitAsFormData) {
        const formData = new FormData();
        Object.keys(data).forEach((field) => {
          formData.append(field, data[field]);
        });
        options.body = formData;
        options.formData = true;
      } else {
        options.body = JSON.stringify(data);
      }
    }
    if (formName) yield put(startSubmit(formName));
    try {
      let requestURL = url;
      if (data && method === 'GET') {
        requestURL += `?${generateGetParams(data)}`;
      }
      const response = yield call(request, requestURL, options);

      if (formName) yield put(stopSubmit(formName));
      if (successCallback) yield call(successCallback, response);
      if (successAction) yield put(successAction(response));

      if (formName && resetForm) {
        yield put(reset(formName));
      }
    } catch (error) {
      if (error.response) {
        const errorBody = yield error.response.json();
        // Check if CSRF expired. If yes, call me and retry.
        if (errorBody.message && errorBody.message.indexOf('CSRF') > -1 && retriesLeft > 0) {
          const newProcessor = new RequestProcessor();
          yield call(newProcessor.process, {
            method: 'GET',
            url: ME_URL,
            retriesLeft: retriesLeft - 1,
            * generatorCallback() {
              const processor = new RequestProcessor();
              yield call(processor.process, {
                ...params,
                retriesLeft: retriesLeft - 1,
              });
            },
          });
        } else {
          const formErrors = transformToFormErrors(errorBody);

          if (formName) yield put(stopSubmit(formName, formErrors));
          if (failureAction) yield put(failureAction(errorBody));
          if (failureCallback) yield call(failureCallback, error);
        }
      } else {
        // rethrow errors that don't have a response (set by fetch)
        throw error;
      }
    }
    if (generatorCallback) yield generatorCallback();
  }
}

export default RequestProcessor;
